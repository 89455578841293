function closeElement(id) {
  document.querySelector("[data-target='hidden-element-" + id + "']").classList.add('hidden');
  document.querySelector("[data-target='hide-element-link-" + id + "']").classList.add('hidden');
  document.querySelector("[data-target='show-element-link-" + id + "']").classList.remove('hidden');
}

function closeAll(elements) {
    elements.forEach(function(element) {
    var id = element.getAttribute('data-target');
    var id = id.substr(id.length - 1);
    closeElement(id);
  });
}

function openElement(id) {
  let elementToOpen = document.querySelector("[data-target='hidden-element-" + id + "']");

  const showLinks = elementToOpen.parentNode.querySelectorAll("[data-target*='show-element-link']");
  closeAll(showLinks);
  const showElements = elementToOpen.parentNode.querySelectorAll("[data-target*='hide-element-']");
  closeAll(showElements);

  elementToOpen.classList.remove('hidden');
  document.querySelector("[data-target='show-element-link-" + id + "']").classList.add('hidden');
  document.querySelector("[data-target='hide-element-link-" + id + "']").classList.remove('hidden');
}


function initializeOpenCloseElements() {
  const showLinks = document.querySelectorAll("[data-target*='show-element-link']");

  showLinks.forEach(function(element) {
    var id = element.getAttribute('data-target');
    var id = id.substr(id.length - 1);
    element.addEventListener("click", function(){
      openElement(id);
    });
  });

  const hideLinks = document.querySelectorAll("[data-target*='hide-element-link']");
  hideLinks.forEach(function(element) {
    var id = element.getAttribute('data-target');
    var id = id.substr(id.length - 1);
    element.addEventListener("click", function(){
      closeElement(id);
    });
  });
}

export { initializeOpenCloseElements };
