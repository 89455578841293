function getLocation() {
  if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(saveUserLocation);
  } else {
      console.log("Geolocation is not supported by this browser.");
  }
}

function saveUserLocation(position) {
  // console.log("Latitude: " + position.coords.latitude + " Longitude: " + position.coords.longitude);

  // make ajax request to update the activity
  fetch("/users/save_location", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
    },
    body: JSON.stringify({ latitude: position.coords.latitude, longitude: position.coords.longitude }),
    credentials: 'same-origin'
  });
}

export { getLocation };
