import "bootstrap";
import { getLocation } from '../components/geolocation';
import { initializeSelectsOnChange } from '../components/update_select';
import { initializeCommentInputOnChange } from '../components/update_sales_comment';
import { initializeOpenCloseElements } from '../components/open_close_element';
import { initializeDateInputOnChange } from '../components/update_map_by_date';
import { initializeQuantityClientOrderInputs, initialiazeClientOrderHeaderInputs } from '../components/client_order_product_input';
import { closeAlert } from '../components/alert';
import { buildDashboardCharts } from '../components/charts';
import { colors } from '../components/colors';
import { months } from '../components/months';

window.colors = colors;
window.months = months;

const pagesHomePage = document.querySelector("[data-target='pages-home']");
const clientsIndexPage = document.querySelector("[data-target='clients-index']");
const clientsShowPage = document.querySelector("[data-target='clients-show']");
const planMapPage = document.querySelector("[data-target='activities-plan_map']");
const dashboardPage = document.querySelector("[data-target='pages-dashboard']");
const editClientOrder = document.querySelector("[data-target='client_orders-edit']");
const addNewProductToClientOrder = document.querySelector("[data-target='client_order_products-new']");

if (pagesHomePage || clientsIndexPage) {
  getLocation();
  // Register service worker.
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
          .then((reg) => {
            console.log('Service worker registered.', reg);
          });
    });
  }
}

if (clientsShowPage) {
  initializeCommentInputOnChange();
}

if (addNewProductToClientOrder) {
  initializeQuantityClientOrderInputs();
}

if (editClientOrder) {
  initialiazeClientOrderHeaderInputs();
}

initializeSelectsOnChange();
initializeOpenCloseElements();

if (planMapPage) {
  initializeDateInputOnChange();
}

if (dashboardPage) {
  buildDashboardCharts();
  window.buildDashboardCharts = buildDashboardCharts;
}

closeAlert();

require("trix")
require("@rails/actiontext")
