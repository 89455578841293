import Chart from 'chart.js';

function formatDate(date) {
  let month = date.getMonth() + 1;
  let day = date.getDate();

  month = (month > 9 ? '' : '0') + month;
  day = (day > 9 ? '' : '0') + day;

  return date.getFullYear() + '-' + month + '-' + day;
}

function getDateOfWeek(w, y) {
    const d = (w - 1) * 7; // 1st of January + 7 days for each week

    const date = new Date(y, 0, d);
    return formatDate(date);
}

function buildVisitsByWeekChart(element) {
  const activities = JSON.parse(element.dataset.activities);

  const myChart = new Chart(element.getContext('2d'), {
      type: 'bar',
      data: {
          labels: activities.map(hash => getDateOfWeek(hash.week, hash.year)),
          datasets: [{
              label: 'Clientes',
              borderColor: 'transparent',
              backgroundColor: window.colors.primary,
              stack: 0,
              data: activities.map(hash => hash.clients)
          },
          {
              label: 'Leads',
              borderColor: 'transparent',
              backgroundColor: window.colors.lightGrey,
              stack: 0,
              data: activities.map(hash => hash.leads)
          },
          {
              label: 'Outros Contactos',
              borderColor: 'transparent',
              backgroundColor: window.colors.yellow,
              stack: 1,
              data: activities.map(hash => hash.contacts)
          },
          {
              type: 'line',
              label: 'Objectivo',
              borderColor: window.colors.grey,
              backgroundColor: window.colors.grey,
              fill: false,
              data: JSON.parse(element.dataset.objective)
          }]
      },
      options: {
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero:true,
                      suggestedMax: JSON.parse(element.dataset.objective)[0] + 5
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Nº Visitas'
                  }
              }],
              xAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Semanas'
                  }
              }]
          }
      }
  });
}

function buildNewClientsChart(element) {
  const new_clients = JSON.parse(element.dataset.newclients);

  const myChart = new Chart(element.getContext('2d'), {
      type: 'bar',
      data: {
          labels: new_clients.map(hash => hash.date),
          datasets: [{
              label: '# Leads',
              borderColor: 'transparent',
              backgroundColor: window.colors.lightGrey,
              stack: 0,
              data: new_clients.map(hash => hash.leads)
          },
          {
              label: '# Hot Leads',
              borderColor: 'transparent',
              backgroundColor: window.colors.yellow,
              stack: 1,
              data: new_clients.map(hash => hash.hotleads)
          },
          {
              label: '# Visitas Leads',
              borderColor: 'transparent',
              backgroundColor: window.colors.grey,
              stack: 2,
              data: new_clients.map(hash => hash.visits)
          },
          {
              label: '# Novos Clientes',
              borderColor: 'transparent',
              backgroundColor: window.colors.primary,
              stack: 3,
              data: new_clients.map(hash => hash.clients)
          }]
      },
      options: {
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero:true
                  },
                  scaleLabel: {
                    display: true
                  }
              }],
              xAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Semanas'
                  }
              }]
          }
      }
  });
}

function buildVisitsByMonthDistributorChart(element) {
  const activities = JSON.parse(element.dataset.activities);

  const myChart = new Chart(element.getContext('2d'), {
      type: 'bar',
      data: {
          labels: activities.map(hash => [window.months[hash.month - 1], hash.year]),
          datasets: [{
              label: 'Clientes',
              borderColor: 'transparent',
              backgroundColor: window.colors.primary,
              stack: 0,
              data: activities.map(hash => hash.visits)
          },
          {
              label: 'Outros Contactos',
              borderColor: 'transparent',
              backgroundColor: window.colors.yellow,
              stack: 1,
              data: activities.map(hash => hash.contacts)
          },
          {
              type: 'line',
              label: 'Objectivo',
              borderColor: window.colors.grey,
              backgroundColor: window.colors.grey,
              fill: false,
              data: JSON.parse(element.dataset.objective)
          }]
      },
      options: {
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero:true,
                      suggestedMax: JSON.parse(element.dataset.objective)[0] + 5
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Nº Visitas'
                  }
              }],
              xAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Meses'
                  }
              }]
          }
      }
  });
}

function buildVisitsByGenericChart(element, yLabelString, xLabelString) {
  const activities = JSON.parse(element.dataset.activities);

  const labels = activities.map(hash => [ hash.column, '(' + Math.round(((hash.all - hash.not) / hash.all) * 100) + '%)']);

  const myChart = new Chart(element.getContext('2d'), {
      type: 'bar',
      data: {
          labels: labels,
          datasets: [{
              label: 'Em Dia',
              borderColor: 'transparent',
              backgroundColor: window.colors.primary,
              stack: 0,
              data: activities.map(hash => hash.all - hash.not)
          },
          {
              label: 'Em Falta',
              borderColor: 'transparent',
              backgroundColor: window.colors.lightGrey,
              stack: 0,
              data: activities.map(hash => hash.not)
          }]
      },
      options: {
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero:true
                  },
                  scaleLabel: {
                    display: true,
                    labelString: yLabelString
                  }
              }],
              xAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: xLabelString
                  }
              }]
          }
      }
  });
}


function buildDashboardCharts() {
  const visitsByWeekChartElement = document.getElementById("visitsByWeekChart");

  if (visitsByWeekChartElement) {
    buildVisitsByWeekChart(visitsByWeekChartElement);
  }

  const newClientsChartElement = document.getElementById("newClientsChart");

  if (newClientsChartElement) {
    buildNewClientsChart(newClientsChartElement);
  }

  const visitsByMonthDistributorChartElement = document.getElementById("visitsByMonthDistributorChart");

  if (visitsByMonthDistributorChartElement) {
    buildVisitsByMonthDistributorChart(visitsByMonthDistributorChartElement);
  }

  const visitsByFrequencyChartElement = document.getElementById("visitsByFrequencyChart");

  if (visitsByFrequencyChartElement) {
    buildVisitsByGenericChart(visitsByFrequencyChartElement, 'Nº Clientes Activos', 'Segmentos (% em dia)');
  }

  const visitsByDistributorChartElement = document.getElementById("visitsByDistributorChart");

  if (visitsByDistributorChartElement) {
    buildVisitsByGenericChart(visitsByDistributorChartElement, 'Nº Comerciais', 'Distribuidores (% em dia)');
  }
}

export { buildDashboardCharts };
