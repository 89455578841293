function closeAlert() {
  const alertDiv = document.querySelector('.alert');

  if (alertDiv) {
    setTimeout(function(){
        alertDiv.remove();
    }, 3000);
  }
}

export { closeAlert };
