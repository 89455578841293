import { saveHeaderChange } from '../components/client_order_product_input';


function setSelectOptions(select, options) {
  let index = 0;
  let option;

  // Clear the old options
  select.options.length = 0;
  select.options.add(new Option('', ''));

  for (index = 0; index < options.length; ++index) {
    option = options[index];
    select.options.add(new Option(option.name, option.id));
  }
}

function updateSalesOfDistributor(client_select_id, select_id) {
  const clientDistributorSelect = document.querySelector(client_select_id);
  if (clientDistributorSelect) {
    clientDistributorSelect.addEventListener("change", function(){

      var client_id = clientDistributorSelect.options[clientDistributorSelect.selectedIndex].value;

      // make ajax request to update the activity
      fetch("/clients/" + client_id + "/address_info", {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        credentials: 'same-origin'
      })
      .then(response => response.json())
      .then((data) => {
        var options, index, select, option;

        // Get the raw DOM object for the select box
        select = document.querySelector(select_id);

        if (select) {
          setSelectOptions(select, data.contacts);
        }
        // Get the raw DOM object for the select box
        let select2 = document.querySelector('#activity_address_id');

        if (select2) {
          setSelectOptions(select2, data.addresses);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    });
  }
}

function updateClientsOfType(type_select_id, select_id) {
  const typeSelect = document.querySelector(type_select_id);
  if (typeSelect) {
    typeSelect.addEventListener("change", function(){

      var type_id = typeSelect.options[typeSelect.selectedIndex].value;

      // make ajax request to get the clients
      fetch("/clients/by_type?activity_type_id=" + type_id, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        credentials: 'same-origin'
      })
        .then(response => response.json())
        .then((data) => {
          var options, index, select, option, selected_value_id, selected;

          // Get the raw DOM object for the select box
          select = document.querySelector(select_id);

          selected_value_id = select.options[select.selectedIndex].value;

          // Clear the old options
          select.options.length = 0;
          select.options.add(new Option('', ''));

          // Load the new options
          options = data;

          var selected = false;

          for (index = 0; index < options.length; ++index) {
            option = options[index];

            selected = option.id == selected_value_id;
            select.options.add(new Option(option.brand_name, option.id, selected, selected));
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    });
  }
}

function updateClientsBySearch(search_input_id, select_id) {
  const searchInput = document.querySelector(search_input_id);
  if (searchInput) {
    searchInput.addEventListener("change", function(){
      const typeSelect = document.querySelector('#activity_activity_type_id');

      var activity_type_param = "";

      if (typeSelect) {
        var type_id = typeSelect.options[typeSelect.selectedIndex].value;
        activity_type_param = "&activity_type_id=" + type_id;
      }

      var url = "/clients/by_search?search=" + searchInput.value + activity_type_param;

      // make ajax request to get the clients
      fetch(url, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        credentials: 'same-origin'
      })
        .then(response => response.json())
        .then((data) => {
          var options, index, select, option;

          // Get the raw DOM object for the select box
          select = document.querySelector(select_id);

          // Clear the old options
          select.options.length = 0;
          select.options.add(new Option('', ''));

          // Load the new options
          options = data;

          for (index = 0; index < options.length; ++index) {
            option = options[index];
            select.options.add(new Option(option.brand_name, option.id));
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    });
  }
}

function updateUserPlan() {
  const userSelect = document.querySelector('#user_plan');
  if (userSelect) {
    userSelect.addEventListener("change", function(){

      var user_id = userSelect.options[userSelect.selectedIndex].value;

      window.location.href = "/activities?user_plan=" + user_id;
    });
  }
}

function updateClientInfoInOrder(select_id) {
  const clientSelect = document.querySelector(select_id);
  if (clientSelect) {
    clientSelect.addEventListener("change", function(){

      var client_id = clientSelect.options[clientSelect.selectedIndex].value;

      if (client_id) {
        // make ajax request to get the clients
        fetch("/clients/" + client_id + "/address_info", {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
          },
          credentials: 'same-origin'
        })
          .then(response => response.json())
          .then((data) => {
            var disableAddress = false;

            if (data.code !== '') {
              disableAddress = true;
            }

            // Get the raw DOM objects to be edited
            let address = document.querySelector('#client_order_address');
            if (address) {
              address.value = data.address;
              address.disabled = disableAddress;
            }
            let zipcode = document.querySelector('#client_order_zipcode');
            if (zipcode) {
              zipcode.value = data.zipcode;
              zipcode.disabled = disableAddress;
            }
            let phone = document.querySelector('#client_order_phone');
            if (phone) {
              phone.value = data.phone;
            }
            let city = document.querySelector('#client_order_city');
            if (city) {
              city.value = data.city;
              city.disabled = disableAddress;
            }

            var options, index, select, option;

            // Get the raw DOM object for the select box
            select = document.querySelector('#client_order_delegation_id');

            if (select) {
              // Clear the old options
              select.options.length = 0;
              select.options.add(new Option('', ''));

              // Load the new options
              options = data.addresses;

              for (index = 0; index < options.length; ++index) {
                option = options[index];

                select.options.add(new Option(option.name, option.id, false, false));
              }
            }

            saveHeaderChange();
          })
          .catch(error => {
            console.error('Error:', error);
          });
        }
    });
  }
}

function updateDelegationInfoInOrder(select_id) {
  const delegationSelect = document.querySelector(select_id);

  if (delegationSelect) {
    delegationSelect.addEventListener("change", function(){

      var address_id = delegationSelect.options[delegationSelect.selectedIndex].value;

      if (address_id) {
        // make ajax request to get the clients
        fetch("/addresses/" + address_id, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
          },
          credentials: 'same-origin'
        })
          .then(response => response.json())
          .then((data) => {

            // Get the raw DOM objects to be edited
            let address = document.querySelector('#client_order_address');
            if (address) {
              address.value = data.address;
            }
            let zipcode = document.querySelector('#client_order_zipcode');
            if (zipcode) {
              zipcode.value = data.zipcode;
            }
            let phone = document.querySelector('#client_order_phone');
            if (phone) {
              phone.value = data.phone;
            }
            let city = document.querySelector('#client_order_city');
            if (city) {
              city.value = data.city;
            }

            saveHeaderChange();
          })
          .catch(error => {
            console.error('Error:', error);
          });
        }
    });
  }
}

function initializeSelectsOnChange() {
  updateSalesOfDistributor('#client_distributor_id', '#client_distributor_sales_id');
  updateSalesOfDistributor('#activity_client_id', '#activity_contact_id');
  updateClientsOfType('#activity_activity_type_id', '#activity_client_id');
  updateClientsBySearch('#activity_search_client', '#activity_client_id');
  updateClientsBySearch('#client_order_search_client', '#client_order_client_id');
  updateUserPlan();
  updateClientInfoInOrder('#client_order_client_id');
  updateDelegationInfoInOrder('#client_order_delegation_id')
}

export { initializeSelectsOnChange };
