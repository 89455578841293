function initializeCommentInputOnChange() {

  const commentInput = document.querySelector('#sales_comment');
  if (commentInput) {
    commentInput.addEventListener("change", function(){
      saveSalesComment(window.location.href.split('/').pop(), commentInput.value);
    })
  }
}

function saveSalesComment(client_id, comment) {
  fetch("/clients/" + client_id + "/save_sales_comments", {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
    },
    body: JSON.stringify({ comment: comment }),
    credentials: 'same-origin'
  });
}

export { initializeCommentInputOnChange };
