import Swal from 'sweetalert2';

function saveHeaderChange() {
  const form = document.querySelector('form');
  const address = document.getElementById('client_order_address');
  const zipcode = document.getElementById('client_order_zipcode');
  const city = document.getElementById('client_order_city');
  const phone = document.getElementById('client_order_phone');
  const description = document.getElementById('client_order_description');
  const client_id = document.getElementById('client_order_client_id');
  const address_id = document.getElementById('client_order_delegation_id');

  fetch(form.action, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
    },
    body: JSON.stringify({ client_order: { address: address.value,
                                           zipcode: zipcode.value,
                                           city: city.value,
                                           phone: phone.value,
                                           description: description.value,
                                           client_id: client_id.value,
                                           delegation_id: address_id.value }}),
    credentials: 'same-origin'
  })
}

function saveQuantityChange(element) {
  let orderType = document.querySelector('h1');

  if (orderType) {
    orderType = orderType.dataset.clientOrderType;
  }

  const quantityInput = document.querySelector('#product-' + element.dataset.productId + ' .client-order-quantity-input');
  const discountInput = document.querySelector('#product-' + element.dataset.productId + ' .client-order-discount-input');
  const stock = document.querySelector('#product-' + element.dataset.productId + ' .client-order-stock');

  let discountValue = 0;

  if (discountInput) {
    discountValue = discountInput.value;
  }

  if (orderType == 'offer' && Number(stock.innerHTML) < Number(quantityInput.value)) {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Stock Insuficiente',
      showConfirmButton: false,
      timer: 2000
    })
  } else {
    fetch("/save_order_quantity", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({ client_order_id: element.dataset.clientOrderId,
                             product_id: element.dataset.productId,
                             discount: discountValue,
                             quantity: quantityInput.value }),
      credentials: 'same-origin'
    })
    .then(response => response.json())
    .then((data) => {
      if (data.status) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: data.client_order_product.quantity + ' ' + data.product + ' (' + parseFloat(data.client_order_product.price).toFixed(2) + '€)',
          showConfirmButton: false,
          timer: 2000
        })
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }
}

function initializeQuantityClientOrderInputs() {
  const inputs = document.querySelectorAll(".client-order-submit-button");

  inputs.forEach(function(element) {
    element.addEventListener("click", function(){
      saveQuantityChange(element);
    });
  });
};

function initialiazeClientOrderHeaderInputs() {
  const inputs = document.querySelectorAll('#client_order_address,#client_order_zipcode,#client_order_phone,#client_order_city,#client_order_description');

  inputs.forEach(function(element) {
    element.addEventListener("keyup", function(){
      saveHeaderChange();
    });
  });
}


export { initializeQuantityClientOrderInputs, initialiazeClientOrderHeaderInputs, saveHeaderChange };
