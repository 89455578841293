function initializeDateInputOnChange() {

  const DateInput = document.querySelector('.date-in-heading #date');
  if (DateInput) {
    DateInput.addEventListener("change", function(){
      window.location.href = "/activities/plan_map?date=" + DateInput.value;
    })
  }
}

export { initializeDateInputOnChange };
